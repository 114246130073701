import React, { useEffect, useRef, useState } from 'react'
import scrollTo from 'gatsby-plugin-smoothscroll'

export default function Nav() {

  const [navbar, setNavbar] = useState(false)


  const changeBackground = () => {
    if (window.scrollY >= 5) {
      setNavbar(true)
    } else {
      setNavbar(false)
    }
  }

  useEffect(() => {
    document.addEventListener('scroll', changeBackground)
  })


  return (
    <>
      <header className={navbar ? "fixed-top page-header active" : "fixed-top page-header" }>
        <div className="container-fluid container-fluid-max">
          <nav id="navbar" className="navbar navbar-expand-md navbar-dark">
            <a className="navbar-brand myUnderline" onClick={() => scrollTo('#home')}>Nejen tepelná čerpadla</a>
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse justify-content-md-end" id="navbarNav">
              <ul className="navbar-nav">
                <li className="nav-item">
                  <a className="nav-link text-white" onClick={() => scrollTo('#jaktofungujeAnch')}>Jak to funguje</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link text-white" onClick={() => scrollTo('#detailyAnch')}>Detaily</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link text-white" onClick={() => scrollTo('#ukazkyAnch')}>Ukázky montáží</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link text-white" onClick={() => scrollTo('#kontakt')}>Ozvěte se nám</a>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </header>
    </>
  )
}