import { createGlobalStyle
} from 'styled-components'


const GlobalStyles = createGlobalStyle`

a,
a:hover {
  color: inherit; }

a:hover {
  text-decoration: none; }

.bg-lightblue {
  background: #e3f0fc; }

.bg-red {
  background: #4221c4; }

.text-red {
  color: #4221c4; }

.container-fluid-max {
  max-width: 1440px; }

.cover {
  background: no-repeat center/cover; }

.p-15 {
  padding: 15px; }

.btn {
  border-radius: 0;
  font-size: 1.3rem; }

.btn:hover {
  background-color: #6341e8;
}

.navbar-brand {
  font-size: 1.2rem;
  text-transform: uppercase;
  cursor: pointer;
  }

.nav-link {
  cursor: pointer;
}

h3 {
  font-style: italic;
}

.myUnderline {
  border-bottom: 3px solid white; }

.col-4.text-left > p {
  font-weight: bold;
}

.seznamUkazek > div {
  background-color: #e3f0fc;
}

.moje-tile {
  opacity: 0.5!important;
  transition: .5s ease;
  padding: 2.5rem;

}

.moje-tile:hover {
  opacity: 1 !important;
}

a[role="button"] {
  font-size: 2rem;
}

button[class*="btn-block"] {
  color: white;

}

button[class*="btn-block"]:hover {
  color: white;
  background-color: #593cc9;
}

div[class="col-6"] p[class="text-red"] {
  font-weight: bolder;
}

@media screen and (max-width: 650px) {
  h1.mainHeader {
    padding-top: 3rem;
  }


}


@media screen and (min-width: 600px) {
  h1.mainHeader {
    font-size: 3rem;
    font-weight: 500;
  }
  h5 {
    font-size: 1.5rem;
  }
}



/* SCROLL ANIMATIONS
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.page-header.active {
  background: #4221c4;
}

.hero.zoom {
  transform: scale(0.99);
}

.scroll .hero {
  ${'' /* margin-left: -0.5rem;
  margin-right: -0.5rem;
  margin-bottom: -5rem !important; */}
  transform: scale(0.99); }

/* HEADER
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.page-header {
  transition: background 0.5s ease-in-out; }

.page-header .navbar {
  padding: 1rem 0; }

.page-header .navbar-toggler {
  /* the variable is inherited from BS4 built-in variables */
  border-color: #f6f9fc; }

/* My styles
–––––––––––––––––––––––––––––––––––––––––––––––––– */
#home {
  background-image: url("../img/myHero3.jpg"); }
#home h1 {
  font-family: Verdana, sans-serif; }

.hero {
  background-attachment: fixed;
  transition: transform 0.5s ease-in-out; }

.hero::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(rgba(0, 0, 0, 0.5) 0, rgba(0, 0, 0, 0.3) 50%, rgba(0, 0, 0, 0.1) 100%); }

.hero .container-fluid {
  z-index: 10; }

ul#functionList {
  list-style: none;
  margin: 10;
  padding: 0;
}

div.descriptionContainer {
  color: blue;
}

.seznamUkazek h4 {
  text-align: center;
  margin: 5rem;
}










/* Calendar
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.responsive-iframe-container {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  margin: 5% 15%;
  height: 0;
  overflow: hidden; }

.responsive-iframe-container iframe,
.vresponsive-iframe-container object,
.vresponsive-iframe-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

/* MEDIA QUERIES
–––––––––––––––––––––––––––––––––––––––––––––––––– */
/* MEDIUM SCREENS */
@media screen and (max-width: 991px) {
  .page-header {
    background: #4221c4; } }

/* Contact form
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.cool-b4-form .btn {
  border-radius: 0;
  background-color: #4221c4; }

.cool-b4-form .form-group {
  position: relative;
  padding-top: 20px;
  margin-bottom: 0;
  /* If there's only 1 input make it full height */ }
  .cool-b4-form .form-group:first-child:last-child {
    height: 100%; }
    .cool-b4-form .form-group:first-child:last-child .form-control {
      height: 100%; }
  .cool-b4-form .form-group textarea {
    resize: none; }
  .cool-b4-form .form-group .input-highlight {
    content: "";
    width: 0;
    height: 2px;
    background: #4221c4;
    position: absolute;
    display: block;
    bottom: 0;
    transition: width 0.4s ease; }
  .cool-b4-form .form-group label {
    position: absolute;
    left: 10px;
    top: 25px;
    transition: all 0.4s ease; }
  .cool-b4-form .form-group .form-control {
    border-radius: 0;
    border-width: 0 0 2px 0; }
    .cool-b4-form .form-group .form-control:focus, .cool-b4-form .form-group .form-control.hasValue {
      box-shadow: none;
      border-color: #ced4da; }
      .cool-b4-form .form-group .form-control:focus ~ .input-highlight, .cool-b4-form .form-group .form-control.hasValue ~ .input-highlight {
        width: 100%; }
      .cool-b4-form .form-group .form-control:focus ~ label, .cool-b4-form .form-group .form-control.hasValue ~ label {
        transform: scale(0.7);
        top: 0;
        left: 0; }

li > a {
  color: #fff;
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 0.15em;
  display: inline-block;
  padding: 15px 20px;
  position: relative; }
  @media only screen and (min-width: 768px) {
    li > a:after {
      background: none repeat scroll 0 0 transparent;
      bottom: 0;
      content: "";
      display: block;
      height: 2px;
      left: 50%;
      position: absolute;
      background: #fff;
      transition: width 0.3s ease 0s, left 0.3s ease 0s;
      width: 0; }
    li > a:hover:after {
      width: 100%;
      left: 0; } }

.flex-center a {
  padding-left: 1.5rem;
  padding-right: 1.5rem; }

.fa-facebook:hover {
  color: #3b5998; }

.fa-linkedin:hover {
  color: #0e76a8; }

.maple {
  display: none;
}
`


export default GlobalStyles;