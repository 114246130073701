import React from "react"
import GlobalStyles from "../styles/GlobalStyles"
import Nav from "./Nav"
import Helmet from "react-helmet"
import { withPrefix, Link } from "gatsby"

export default function Layout({ children }) {
  return (
    <div>
      <Helmet>
        <script src="https://code.jquery.com/jquery-3.3.1.min.js"></script>
        <script src="https://stackpath.bootstrapcdn.com/bootstrap/4.2.1/js/bootstrap.min.js"></script>
        <script src={withPrefix('./static/script.js')} type="text/javascript" />
      </Helmet>
      <GlobalStyles />
      <Nav />
      {children}
    </div>
  )
}
